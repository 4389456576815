import React from 'react'
import '../styles/index.css'

const Index = () => (
  <main
    style={{
      backgroundColor: '#3e9652',
      margin: 0,
      width: '100%',
      maxWidth: '100%',
      height: '100%',
      color: '#fffcf2'
    }}
  >
    <title>Cadus Oy</title>
    <div
      style={{
        width: '100%'
      }}
    >
      <h1
        style={{ margin: 0, fontSize: '75px', textShadow: '2px 2px #000000' }}
      >
        Kaipaatko verkkosivuja?
      </h1>
      <h2 style={{ margin: 0 }}>
        Ota yhteyttä: <a href="mailto:info@cadus.fi">info@cadus.fi</a>
      </h2>
    </div>
  </main>
)

export default Index
